import React from "react";
import SuccessView from "../views/SuccessView";
import PubSub from "../pubsub";
import axios from 'axios';
import helperFunctions from "../tools/helperFunctions";
import "../css/success_view.scss"
const baseURL = process.env.REACT_APP_BASE_URL;
const urlParams = new URLSearchParams(window.location.search);
const quoteID = urlParams.get('quote_id');



class SuccessController extends React.Component{
    state = {
        quoteDownloaded: false,
    }
    render(){
        const quoteHeader= ((quoteID !== null)? "Your quote was successfully submitted!":"Your quote was not found!")
        const quoteBody = ((quoteID !== null)? "Your quote is ready to be downloaded and a representative will follow up with you shortly! A copy of this quote has also been sent to your email. Be sure to check your spam folder if you cannot find it.":"Contact us or check your spam folder to find an existing quote or start a new quote.")

        return(
       <SuccessView >
          <success-header>{quoteHeader}</success-header>
          <success-body>{quoteBody}</success-body>
          <success-ctas>
            {(quoteID !== null)?
            <quote-cta className="download-quote" onClick={()=>{
                this.downloadQuotePDF()
            }}>Download Quote</quote-cta>
            :
            <quote-cta className="download-quote" onClick={()=>{
                 window.location.pathname ="/";
            }}>Contact Us</quote-cta>
          }
          </success-ctas>

          <contact-us-link onClick={()=>{
               window.location.href = "mailto:info@pssk12.com";
          }}></contact-us-link>
        </SuccessView>

        )
    }

    componentDidMount(){
        if(quoteID === null){

        }
    }

    downloadQuotePDF(){
          PubSub.publish.is_loading(true)
          const url = "quote/" + quoteID + "/download";
        axios({
            headers:{
            'Content-Type': 'application/json',
            'Authorization': "none",
            },
            method: "POST",
            url: baseURL + url
        })
        .then(response => {

            if(response.status  === 200){
               this.setState(()=>({
                     quoteDownloaded: true
               }),()=>{
                    this.downloadResourceAsPDF(response.data.encoded_pdf)
                    PubSub.publish.new_toast_message({
                        type: 'success',
                        header: "Your quote was downloaded!",
                        message: "Check your downloads folder to view the PDF document."
                    })
               })
            }
        })
        .catch(error => {
            helperFunctions.handleError(error)
        })
        .then(()=>{
            PubSub.publish.is_loading(false)
        });
    }

    downloadResourceAsPDF(encoded_pdf){
          const linkSource = "data:application/octet-stream;base64," + encoded_pdf;
          const link = document.createElement('a');
          link.href = linkSource;
          link.target = "_blank";
          link.rel = "noreferrer";
          link.download = "PSS Poster Maker System Quote.pdf";
          link.click()
     }
}



export default SuccessController